// React Basic and Bootstrap
import React, {useEffect} from "react";

//Import Images

// Import Generic Components
import Section from "./Section";
import Start from "./Start";

const Index = () => {

    // useEffect(() => {
    //     document.getElementById("buyButton")?.classList.add("nav-light");
    //     document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    // });

    return (
        <React.Fragment>
            <Section/>
            <Start/>
        </React.Fragment>
    );
}

export default Index;
